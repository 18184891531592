<template>
  <FocusTrap>
    <div  id="settlement_card" class="card">

      <div class="card-header header-elements-inline" style="background-color: whitesmoke;padding: 10px;" >
        <h5 v-if="voucher.id == 0" class="card-title">Bank Settlement Creation</h5>
        <h5 v-if="voucher.id > 0" class="card-title"> Doc No: {{voucher.doc_no}}  Bank Settlement Updation</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" @click="loadLedgers" ></a>
            <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="d-flex ">

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Bank</label>
              <select id="cmbLedger" class="form-control select" autofocus="" required="" v-if="voucher" v-model="voucher.ledger_id" >
                <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                  {{ ledger.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-7">

          </div>

          <div class="col-md-2">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Date</label>
              <input id="txtDate" type="date" class="form-control text-right" v-if="voucher" v-model="voucher.doc_date">
            </div>
          </div>
        </div>

        <div class="d-flex ">

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Batch</label>
              <input type="text" class="form-control" maxlength="50" placeholder="Card Batch No" v-if="voucher" v-model="voucher.batch"/>
            </div>
          </div>

          <div class="col-md-9">
          </div>

        </div>

        <div class="d-flex ">

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Amount</label>
              <input type="number" class="form-control text-right" required="" v-if="voucher" v-model="voucher.amount"/>
            </div>
          </div>
          <div class="col-md-9">

          </div>

        </div>

        <div class="d-flex align-items-end p-0" style="padding: 0px;">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Narration</label>
              <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
            </div>
          </div>
          <div class="col-md-6">
          </div>
          <div class="col-md-3 d-flex justify-content-end">
            <SaveButton title="Save" @handle_save_action="saveVoucher"></SaveButton>
            <div style="width: 10px;"></div>
            <CloseButton title="Clear" @handle_close_action="clear" ></CloseButton>
          </div>
        </div>

      </div>

    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/buttons/SaveButton'
import CloseButton from '../../../components/core/buttons/CloseButton'

export default {
  name: 'BankSettlementForm',
  components: {
    CloseButton,
    SaveButton
  },
  store,
  data () {
    return {
      readonly: false,
      ledgers: [],
      voucher: JSON.parse('{"id":0,"status":"Active","finyear":2000,"brn_id":1,"doc_date":"0001-01-01","doc_no":"","ledger_id":0,"batch":"","amount":0,"remarks":"","ledger":{"id":0,"name":""}}'),
    }
  },
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","finyear":2000,"brn_id":1,"doc_date":"0001-01-01","doc_no":"","ledger_id":0,"batch":"","amount":0,"remarks":"","ledger":{"id":0,"name":""}}')
    }
  },
  beforeMount () {
    this.voucher = this.myvoucher;
  },
  created () {
    this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
  },
  mounted () {
    const self = this;
    self.loadLedgers();
    if (self.$data.voucher.doc_date == '0001-01-01') {
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    }
    $('#cmbLedger').focus();

  },
  methods: {
    closeThis () {
      this.$emit('banksettlement_window_closed');
      if (this.voucher.id == 0) {
        this.$router.push('/');
      }
    },
    clear () {
      const self = this;
      self.$data.voucher = JSON.parse('{"id":0,"status":"Active","finyear":2000,"brn_id":1,"doc_date":"0001-01-01","doc_no":"","ledger_id":0,"batch":"","amount":0,"remarks":"","ledger":{"id":0,"name":""}}');
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    },
    loadLedgers(){
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#settlement_card').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      // fetch the ledgers
      self.$data.ledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/bank_ledgers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            resp.data.forEach(function (ldgr) {
              self.$data.ledgers.push(ldgr);
            });
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes', text: err.toString(), type: 'error' });
      }).finally(function () {
        $('#settlement_card').unblock();
      });
    },
    saveVoucher () {
      const self = this;

      try {
        self.$data.voucher.amount =  parseFloat( self.$data.voucher.amount);
        if(self.$data.voucher.ledger_id < 1) {
          $('#cmbLedger').focus();
          alert('Invalid Bank');
          return;
        }else if( parseFloat(self.$data.voucher.amount) <= 0 ){
          $('#txtamount').focus();
          alert('Invalid Amount');
          return;
        }

        let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher));
        myvoucher.cmp_id = self.$store.state.user.company.id;
        myvoucher.brn_id = self.$store.state.user.branch.id;
        myvoucher.finyear = self.$store.state.user.finyear;
        myvoucher.doc_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');
        myvoucher.amount =  parseFloat(self.$data.voucher.amount);

        console.log(JSON.stringify(myvoucher));

        const requestOptions = {
          method: ((myvoucher.id == 0) ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        }

        $('#settlement_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/banksettlement`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            self.$emit('banksettlement_saved', resp.data);
            swal({ title: 'Success', type: 'success', text: resp.msg, onClose: () => { $('#cmbLedger').focus()}, timer:1500  });
            self.clear();
          } else {
            swal({ title: 'Oops', text: resp.msg, type: 'error' , onClose: () => { $('#cmbLedger').focus()}, timer:3000 });
          }
        }).catch(function (err) {
          swal({ title: 'Oops', text: err.toString(), type: 'error', onClose: () => { $('#cmbLedger').focus()}, timer:3000  });
        }).finally(function () {
          $('#settlement_card').unblock();
        });
      }catch (e) {
        alert(e);
      }
    },

  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
